































































































































































































































import { ref, onMounted, defineComponent } from '@vue/composition-api'
import { gsap } from 'gsap'
import { SplitText } from '@/inc/vendor/gsap-extra/SplitText'

import HomePromo from '@/components/home/Promo.vue'

export default defineComponent({
  components: {
    HomePromo,
  },
  props: {
    content: Object,
    mode: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'xl',
    },
    promoLabel: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const spacerClass = `h-spacer-${
      props.mode === 'shaded' || props.mode === 'home' ? 'xxl' : props.size
    }`

    const hero = ref<HTMLElement | null>(null)
    const onboard = ref<HTMLElement | null>(null)
    const text = ref<HTMLElement | null>(null)
    const link = ref<HTMLElement | null>(null)
    const pictureContainer = ref<HTMLElement | null>(null)
    const picture = ref<HTMLImageElement | null>(null)
    const video = ref<HTMLVideoElement | null>(null)
    const cta = ref<HTMLElement | null>(null)
    const promo = ref<InstanceType<typeof HomePromo> | null>(null)

    const basicScale = 1.1
    const handlingScroll = () => {
      const normalizeScale = window.scrollY / 8000
      const scale = basicScale + normalizeScale
      // If (window.scrollY >= window.innerHeight) {
      //   if (hero.value) {
      //     hero.value.classList.add('stop-scroll')
      //   }
      //   window.removeEventListener('scroll', handlingScroll)
      // }
      gsap.to(picture.value, {
        duration: 1,
        y: window.scrollY / 10,
        scale,
        ease: 'power4.out',
      })
    }

    const startOnboard = () => {
      hero.value!.classList.remove('before-onboard')

      if (!video.value) {
        return
      }

      // Const width = pictureContainer.value?.offsetWidth
      // const height = pictureContainer.value?.offsetHeight
      const clipProgress = {
        x: 100,
      }

      const splitTitle = new SplitText(text.value, {
        type: 'words',
      })
      const tl = gsap.timeline({
        delay: ctx.root.$route.path === '/' ? 1.6 : 0,
        onComplete: () => {
          if (pictureContainer.value) {
            tl.set(pictureContainer.value, { clearProps: 'all' })
          }
        },
      })

      tl.add('onBoarding').from(
        clipProgress,
        {
          duration: 1,
          x: 0,
          ease: 'power4.inOut',
          onUpdate: () => {
            if (pictureContainer.value) {
              pictureContainer.value!.style.clip = `rect(0, ${clipProgress.x}vw, 100vh , 0 )`
            }
          },
        },
        '<+0'
      )

      if (picture.value) {
        tl.to(
          [picture.value, video.value],
          {
            duration: 1,
            scale: 1,
            ease: 'power4.out',
          },
          '<+0.1'
        ).to(picture.value, { opacity: 0, duration: 0.2 }, '<')
      } else {
        tl.to(
          video.value,
          {
            duration: 1,
            scale: 1.1,
            ease: 'power4.out',
          },
          '<+0.1'
        )
      }

      tl.add(() => video.value?.play(), '<')
        .from(
          splitTitle.words,
          {
            duration: 2,
            opacity: 0,
            stagger: 0.1,
            y: 20,
            ease: 'power4.out',
          },
          '<+0.2'
        )
        .from(
          cta.value,
          {
            duration: 0.7,
            opacity: 0,
            y: 20,
            ease: 'power4.out',
          },
          '<+0.2'
        )

      if (promo.value) {
        tl.from(
          promo.value.$el,
          { opacity: 0, scale: 1.1, duration: 1.2, ease: 'power4.out' },
          '<+0.4'
        )
      }
    }

    onMounted(() => {
      // Scroll Handling
      window.addEventListener('scroll', handlingScroll)

      console.log('Hero: is picture cached?', picture.value?.complete)

      // If no picture or picture is cached, start onboard
      if (!picture.value || picture.value?.complete) {
        ctx.root.$once('page:ready', () => {
          startOnboard()
        })
      } else {
        // Or wait for it to load
        picture.value.onload = () => {
          console.log('Hero: picture loaded')
          startOnboard()
        }
      }
    })

    return {
      cta,
      hero,
      onboard,
      text,
      link,
      pictureContainer,
      picture,
      video,
      spacerClass,
      promo,
    }
  },
})
