










import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
// import FlexibleGallery from '@/components/flexible/Gallery.vue'
import FlexibleCaseStudy from '@/components/flexible/CaseStudy.vue'
import HomeHero from '@/components/home/Hero.vue'

export default defineComponent({
  name: 'home',
  components: {
    HomeHero,
    FlexibleCaseStudy,
  },

  setup() {
    const { content } = useGetters(['content'])

    const getCtaPromoAnchor = () => {
      const ctaPromos = content.value.flexibleContent.filter(
        component => component.component === 'ctaPromo'
      )

      const ctaPromo = ctaPromos.find(cta => cta.content.anchor)

      if (!ctaPromo) {
        return null
      }

      return ctaPromo.content.anchor.label
    }

    return {
      content,
      getCtaPromoAnchor,
    }
  },
})
